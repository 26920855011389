import bunyan from 'bunyan'
import { canUseDom } from '@sainsburys-tech/boltui-utils'

// Set 'level' in each log to be the string level name instead of integer level (e.g. print "ERROR" instead of 50 as the level for error logs)
// Needed for Elasticsearch indexing in Bosun as other apps are logging 'level' as strings so any logs with 'level' that aren't strings won't appear
const wrappedStdout = () => {
  return {
    write: (entry) => {
      const logObject = JSON.parse(entry)
      logObject.level = bunyan.nameFromLevel[logObject.level].toUpperCase()
      process.stdout.write(`${JSON.stringify(logObject)}\n`)
    },
  }
}

const createLoggerInstance = (name) => {
  return bunyan.createLogger({ name, stream: wrappedStdout(), serializers: { err: bunyan?.stdSerializers?.err } })
}

export default (type, name, responseData = '', msg = '') =>
  !canUseDom() && createLoggerInstance(name)[type](responseData, msg)
